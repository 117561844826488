import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
// import "./sold.scss";

import { GlobalContex } from "../../../../globalContex";

import copyToClipboard from "../../../../static/images/icons/copy.svg";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";
import { message } from "antd";
import OrdersDrawer from "./OrdersDrawer";
import OrderSubDrawer from "./OrdersSubDrawer"
// import ActionIndex from "./CategorySubDrawer/ActionIndex"
// import ProductDataSubDrawer from "./ProductsDataSubDrawer"

const Orders = () => {
    const { loginData, globalSearch, collapse, getDisplayDate, getDisplayTime, getDays, fetchProductData, setFetchProductData, productSubDrawer, setProductSubDrawer, refetchProduct, setRefechProduct, selectedBusiness, productDrawerOpen, setProductsDrawerOpen, TheFormData, setTheFormData,
        productForm, setSelectedFormData, fetchForm, setFetchForm, selectedOrder, setSelectedOrder,
        ordersDrawerOpen, setOrdersDrawerOpen, ordersSubDrawer, setOrdersSubDrawer,
        selectedSubOrder, setSelectedSubOrder, userDetails, setUserDetails, selectedCustomer } = useContext(GlobalContex);

    const [allOrders, setAllOrders] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState("")
    const [selectedBox, setSelectedBox] = useState("")
    //   const [page, setPage] = useState(0);

    //   let limit = Math.ceil((window.innerHeight - 175) / 90) + 1;

    // useEffect(() => {
    //   setDataLoading(true);
    //   console.log(Math.ceil((window.innerHeight - 175) / 90), "innerHeight")
    //   let limit = Math.ceil((window.innerHeight - 175) / 90) + 1;
    //   axios
    //     .get(
    //       `https://comms.globalxchange.io/coin/vault/service/users/holdings/data/get?skip=0&limit=${limit}`
    //     )
    //     .then((res) => {
    //       setAllMarketsverseUsers(res.data.users);
    //       setDataLoading(false);
    //     });
    // }, [])

    const fetchData = async () => {
        // console.log(pageNumber, "page")
        setDataLoading(true);

        // console.log("call API")

        setDataLoading(true);

        try {
            let response
            if (selectedCustomer) {
                response = await fetch(`https://frissly.apimachine.com/api/order/user?user_id=${selectedCustomer?._id}`)
            } else {
                response = await fetch(`https://frissly.apimachine.com/api/order/business?businessusername=${selectedBusiness?.businessusername}`);
            }
            const jsonData = await response.json();
            const newData = jsonData.data;

            setAllOrders(newData);
            console.log(newData, "newData")
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        setDataLoading(false);

    };

    const clickedBox = async (item) => {
        if (selectedBox === item) {
            setSelectedBox("")
        } else {
            setSelectedBox(item)
        }
        try {
            const response = await fetch(`https://frissly.apimachine.com/api/user/${item?.user_id}`);
            const jsonData = await response.json();
            const newData = jsonData.data;

            setUserDetails(newData);
            console.log(newData, "user-data")
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }






    useEffect(() => {
        fetchData();
    }, [fetchProductData, refetchProduct, setRefechProduct, selectedBusiness, selectedCustomer]);



    const conditionalResposiveView = (
        data,
        dataLoading,
        desktopDataGrid,
        mobileDataGrid,
        showSubDraw
    ) => {
        return (
            <>
                <div className="desktopWrapper">
                    <div style={{ width: "100%" }}>
                        {headerSection("listGrid", desktopDataGrid)}
                    </div>
                    <div id="usersData"
                        style={{
                            // display: "flex",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: window.innerHeight - 175,
                            overflow: 'auto'
                        }}
                    >
                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data.filter(
                                    (item) =>
                                        item?.status
                                            ?.toLowerCase()
                                            .includes(globalSearch.toLowerCase()) ||
                                        item?.paymentStatus
                                            ?.toLowerCase()
                                            .includes(globalSearch.toLowerCase())).map((item) => {
                                                return contentSection(item, "listDataGrid", desktopDataGrid);
                                            })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", desktopDataGrid)
                        )}
                        <OrdersDrawer />
                        <OrderSubDrawer />
                    </div>
                </div>

                <div className="mobileWrapper">
                    <div style={{ overflowY: "scroll", height: "80vh" }}>
                        {headerSection("listGridMobile", mobileDataGrid)}

                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data?.map((item, index) => {
                                    return contentSection(
                                        item,
                                        "listDataGridMobile",
                                        mobileDataGrid
                                    );
                                })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", mobileDataGrid)
                        )}
                        <OrdersDrawer />
                        <OrderSubDrawer />
                    </div>
                </div>
            </>
        );
    };

    // Change these three Sections according to the design

    const headerSection = (gridClass, gridValues) => {
        return (
            <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
                <div>Customer</div>
                <div style={{ textAlign: "left" }}>Order ID</div>
                <div style={{ textAlign: "left" }}>Date</div>
                <div style={{ textAlign: "left" }}>Quantity</div>
                <div style={{ textAlign: "left" }}>Status</div>
                <div style={{ textAlign: "left" }}>Amount</div>
                <div style={{ textAlign: "left" }}></div>
            </div>
        );
    };

    const contentSection = (item, gridClass, gridValues) => {
        return (
            <div
                style={{
                    height: selectedBox === item ? "520px" : "",
                    borderBottom: selectedBox === item ? "solid 0.5px #E7E7E7" : "none"
                }}>
                <div
                    onClick={(e) => clickedBox(item)}
                    className={gridClass}
                    style={{
                        gridTemplateColumns: gridValues,
                        alignItems: selectedBox === item ? "start" : "",
                        paddingTop: selectedBox === item ? "35px" : "",
                        borderBottom: selectedBox !== item ? "solid 0.5px #E7E7E7" : "none"
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div >
                            <div className="title">{item?.user_id?.name} &nbsp;</div>
                            <div className="subtitle">{item?.user_id?._id}</div>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                        <div className="title">{item?._id}</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                        <div className="title">{getDisplayDate(item?.createdAt)} At {getDisplayTime(item?.createdAt)}</div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            marginRight: "20px",
                        }}
                    >
                        <div className="title">{item?.products?.length} Products / {item?.products.reduce((total, product) => total + product.quantity, 0)} Units</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            marginRight: "20px",
                        }}
                    >
                        <div className="title">{item?.paymentStatus?.charAt(0)?.toUpperCase() + item?.paymentStatus?.slice(1)}</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            marginRight: "20px",
                        }}
                    >
                        <div className="title">{'\u20B9'}{item?.orderTotal?.$numberDecimal}</div>
                    </div>
                    <div className="btngrp">
                        <button className="actionbtn"
                            onClick={(e) => {
                                setSelectedOrder(item);
                                setOrdersDrawerOpen(true);
                            }}
                        >Actions</button>
                        <button className="actionbtn" style={{ width: "135px" }}
                        // onClick={() => {
                        //     setTheFormData(true)
                        //     setSelectedFormData("Product")
                        //     setFetchForm(item)
                        // }}
                        >
                            Order page</button>
                    </div>
                </div>
                <div style={{ display: selectedBox === item ? "grid" : "none", gridTemplateColumns: "1fr 1fr" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", padding: "10px 30px" }}>
                        <div>
                            <div className="orderTitle">Name</div>
                            <div className="orderSubTitle">{userDetails?.userName}</div>
                            <br />
                            <div className="orderTitle">Phone</div>
                            <div className="orderSubTitle">{userDetails?.phone}</div>
                            <br />
                            <div className="orderTitle">Address</div>
                            <div className="orderSubTitle">{item?.deliveryAddress?.address1}</div>
                            <br />
                            <div className="orderTitle">Pincode</div>
                            <div className="orderSubTitle">{item?.deliveryAddress?.pincode}</div>
                            <br />
                            <div className="orderTitle">Delivery Type</div>
                            <div className="orderSubTitle">{item?.deliveryAddress?.deliveryType}</div>
                        </div>
                        <div>
                            <div className="orderTitle">User id</div>
                            <div className="orderSubTitle">{userDetails?._id}</div>
                            <br />
                            <div className="orderTitle">Email</div>
                            <div className="orderSubTitle">{userDetails?.email}</div>
                            <br />
                            <div className="orderTitle" style={{ color: "white" }}>Address</div>
                            <div className="orderSubTitle" style={{ color: "white" }}>{item?.deliveryAddress?.address1}</div>
                            <br />
                            <div className="orderTitle">City</div>
                            <div className="orderSubTitle">{item?.deliveryAddress?.city}</div>
                            <br />
                            <div className="orderTitle">Landmark</div>
                            <div className="orderSubTitle">{item?.deliveryAddress?.landmark}</div>
                        </div>
                    </div>
                    <div className="rightBox">
                        <div className="productBox">
                            {item?.products?.map((field) => {
                                return <div className="productDetail">
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            <img src={field?.product?.icon} alt="theicon" className="productIcon" />
                                        </div>
                                        <div style={{ marginLeft: "15px" }}>
                                            <div className="productTitle">{field?.product?.name}</div>
                                            <div className="productWeight">{field?.product?.dimension?.[0]?.weight?.$numberDecimal} grams</div>
                                            <div className="productUnits">{field?.quantity} units&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'\u20B9'}{field?.total?.$numberDecimal}</div>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <button className="actionbtn" style={{ background: "#6669B0", color: "white", fontWeight: 700, cursor: "pointer" }} onClick={() => {
                                            setOrdersSubDrawer(true)
                                            setSelectedSubOrder(field)
                                            setSelectedOrder(item)
                                        }}>Change #</button>
                                        <button className="actionbtn" style={{ background: "black", color: "white", fontWeight: 700, cursor: "pointer" }}>Remove</button>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const loadingSection = (gridClass, gridValues) => {
        return Array(10)
            .fill("")
            .map((item, i) => {
                return (
                    <div
                        className={gridClass}
                        style={{
                            width: "100%",
                            gridTemplateColumns: gridValues,
                            // borderBottom: "solid 0.5px #EEEEEE",
                        }}
                    >
                        <div key={i} style={{ display: "flex", alignItems: "center" }}>
                            <Skeleton
                                className="dp"
                                circle
                                width={50}
                                height={50}
                                style={{ marginRight: "20px" }}
                            />
                            <div className="userDetail">
                                <Skeleton width={100} />
                                <Skeleton width={120} style={{ height: "10px" }} />
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={120} />
                            {/* <Skeleton width={80} style={{ height: "10px" }} /> */}
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={120} />
                            {/* <Skeleton width={80} style={{ height: "10px" }} /> */}
                        </div>
                        {/* <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={70} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={100} />
                        </div> */}
                    </div>
                );
            });
    };

    return (
        <div>
            {conditionalResposiveView(
                allOrders,
                dataLoading,
                "1fr 1.2fr 1.2fr 1fr 0.6fr 0.6fr 1fr", // Desktop view Grid columns
                "250px 200px 200px" // Mobile view Grid columns
            )}
        </div>
    );
};

export default Orders;
